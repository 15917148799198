<template>
  <CampaignManager
    type="CAMPAIGN_MANAGER_TOMORROW"
    :func-get-opportunities="getOpportunityByEndDate"
    :show-date-picker="false"
    :end-date="endDate"
  ></CampaignManager>
</template>
<script>
import CampaignManager from '@/views/private/modules/thirdParty/campaignManager/CampaignManager.vue';
import { getOpportunityByEndDate } from '@/services/modules/ThirdParty/opportunity';
import { TODAY } from '@/utils/dateTime/today';
import { addDays } from '@/utils/dateTime/addDays';

export default {
  name: 'CampaignManagerTomorrow',
  components: { CampaignManager },
  data: () => ({
    endDate: addDays(TODAY, 1),
  }),
  methods: {
    getOpportunityByEndDate,
  },
};
</script>
